import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'account',
  initialState: {
    budgetLines: [],
    householdLines: [],
    carLines: [],
    loanLines: [],
    creditCardLines: [],
    foodLines: [],
    girlsLines: [],
    subscriptionLines: [],
    activityLines: [],
    monthlySalary: 2542.44,
    transactions: [
      {id: 1, statementRef: '2012', amount: 123, accountName: 'Some account'}
    ]
  },
  reducers: {
    setBudgetLines: (state, action) => {
      state.budgetLines = action.payload;
    },
    setHouseholdLines: (state, action) => {
      state.householdLines = action.payload;
    },
    setCarLines: (state, action) => {
      state.carLines = action.payload;
    },
    setLoanLines: (state, action) => {
      state.loanLines = action.payload;
    },
    setCreditCardLines: (state, action) => {
      state.creditCardLines = action.payload;
    },
    setFoodLines: (state, action) => {
      state.foodLines = action.payload;
    },
    setGirlsLines: (state, action) => {
      state.girlsLines = action.payload;
    },
    setSubscriptionLines: (state, action) => {
      state.subscriptionLines = action.payload;
    },
    setActivityLines: (state, action) => {
      state.activityLines = action.payload;
    },
    setTotalMonthlyPredictedExpenditure: (state, action) => {
      state.totalMonthlyPredictedExpenditure = action.payload;
    },
    setTransactions: (state, action) => {
      state.currentMonthTransactions = action.payload;
    }
  },
});

export const { setBudgetLines, setHouseholdLines, setCarLines, setLoanLines, setCreditCardLines, setFoodLines, setGirlsLines, setSubscriptionLines, setActivityLines, setTotalMonthlyPredictedExpenditure, setTransactions } = userSlice.actions;

export const selectMonthlySalary = state => state.account.monthlySalary;

export default userSlice.reducer;
