import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyA9zpBnmwS07nNRrfHbZN3cLps9_QyLc58',
  authDomain: 'naperone-9e148.firebaseapp.com',
  databaseURL: 'https://naperone-9e148.firebaseio.com',
  projectId: 'naperone-9e148',
  storageBucket: 'naperone-9e148.appspot.com',
  messagingSenderId: 675922540360,
};

class Firebase {
  constructor(){
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.database();
  }

  //Auth API

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);


  //User API

  user = (uid) => this.db.ref(`datingApp/users/${uid}`);

  users = (uid) => this.db.ref('datingApp/users');

  profile = (uid) => this.db.ref(`datingApp/users/${uid}/profile`);

  preferences = (uid) => this.db.ref(`datingApp/users/${uid}/preferences`);

  budgetLines = (uid) => this.db.ref(`budget-app/users/${uid}/budget-lines`);

  budgetLineItem = (uid, accountID) => this.db.ref(`budget-app/users/${uid}/budget-lines/${accountID}`);

  locations = (uid) => this.db.ref(`minecraft-buddy/users/${uid}/locations`);

  location = (uid, itemID) => this.db.ref(`minecraft-buddy/users/${uid}/locations/${itemID}`);



}

export default Firebase;
