import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navigation from './components/Navigation';

import LandingPage from './components/Landing';
import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import PasswordForgetPage from './components/PasswordForget';
import HomePage from './components/Pages/HomePage';
import LocationsPage from './components/Pages/LocationsPage';

import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';

import style from './App.module.css';

const App = () => (
  <Router>
   <div class={style.page}>
     <Navigation />

     <Route exact path={ROUTES.LANDING} component={LandingPage} />
     <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
     <Route path={ROUTES.SIGN_IN} component={SignInPage} />
     <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
     <Route path={ROUTES.HOME} component={HomePage} />
     <Route path={ROUTES.LOCATIONS} component={LocationsPage} />
   </div>
 </Router>
);

export default withAuthentication(App);
