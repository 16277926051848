import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../appSlices/userSlice';
import accountReducer from '../appSlices/accountSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    account: accountReducer
  },
});
