import React from 'react';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { Form, Button } from 'react-bootstrap';
import style from './SignUpPage.module.css';

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <div>
         <Form onSubmit={this.onSubmit}>

         <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" name="username" value={username} onChange={this.onChange} placeholder="Enter your name" />
          </Form.Group>

          <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={email} onChange={this.onChange} placeholder="Enter email address" />
          </Form.Group>

          <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" name="passwordOne" value={passwordOne} onChange={this.onChange} placeholder="Enter password" />
              <Form.Text className="text-muted">
                Password must contain at least 6 digits, and 2 of those should be numbers (I think)
              </Form.Text>
          </Form.Group>

          <Form.Group>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" name="passwordTwo" value={passwordTwo} onChange={this.onChange} placeholder="Enter password" />
          </Form.Group>

          <Button variant="primary" disabled={isInvalid} type="submit">
              Sign up
          </Button>
          {error && <p>{error.message}</p>}
        </Form>
      </div>
      );
  }
}

export default withRouter(withFirebase(SignUpForm));
