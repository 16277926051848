import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import _ from 'underscore';

class LocationsList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            locations: [],
            loading: false,
        }

    }

componentDidMount() {
    this.setState({ loading: true });

    console.log('locations list straight from firebase: ', this.props.firebase.locations(this.props.authUserUID));

    this.props.firebase.locations(this.props.authUserUID).on('value', snapshot => {
        const locationsObject = snapshot.val();
        console.log('locationsObject: ', locationsObject);

        const listOfLocations = Object.keys(locationsObject).map(key => ({
            ...locationsObject[key],
            uid: key,
            }));

        this.setState({
        locations: listOfLocations,
        loading: false,
        });
        
    });

}

componentWillUnmount() {
    this.props.firebase.locations(this.props.authUserUID).off();
  }

    render() {

        const { locations } = this.state;
        console.log('locations: ', locations);

        return(

            <div>
                <Table style={{ color: '#fff' }}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>X</th>
                        <th>Y</th>
                        <th>Z</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        { locations.map(location => (
                            <tr key={location.uid}>
                                <td>{location.locationName}</td>
                                <td>{location.description}</td>
                                <td>{location.xCoordinate}</td>
                                <td>{location.yCoordinate}</td>
                                <td>{location.zCoordinate}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
               
            </div>

        );
    }
}

export default withFirebase(LocationsList);