import React from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import style from './Page.module.css';
import AddLocationPage from '../AddLocation';

const HomePage = () => (
  <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <Container fluid className={style.page}>
              <Row>
                <Col>
                  <AddLocationPage authUserUID={authUser.uid} />
                </Col>
              </Row>
            </Container>

            
          </div>
        )}
      </AuthUserContext.Consumer>
  
);

const condition = authUser => !!authUser;

const select = appState => {
  return {
      bioText: appState.user.bioText,
      editMode: appState.user.editMode,
      currentUser: appState.user.currentUser,
      firebaseUser: appState.user.firebaseUser,
      budgetLines: appState.account.budgetLines
  }
}

const ourWrapperFunction = connect(select);

export default withAuthorization(condition)(ourWrapperFunction(HomePage));