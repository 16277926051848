import React from 'react';
import { Link } from 'react-router-dom';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import { Nav } from 'react-bootstrap';
import styles from './Navigation.module.css';

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

//<Nav
//    activeKey="/home"
//    onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
//  ></Nav>

const NavigationAuth = () => (
  <Nav className={styles.navbar}>
    <Nav.Item>
      <Link to={ROUTES.HOME} className={styles.navLink}>Add location</Link>
    </Nav.Item>
    <Nav.Item>
      <Link to={ROUTES.LOCATIONS} className={styles.navLink}>View locations</Link>
    </Nav.Item>
    <Nav.Item className="pull-right" style={{ marginLeft: "100px", paddingTop: "15px" }}>
      <SignOutButton />
    </Nav.Item>
  </Nav>
);

const NavigationNonAuth = () => (
  <Nav className={styles.navbar}>
    
  </Nav>
);


export default Navigation;
