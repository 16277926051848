import React from 'react';
import SignUpForm from '../SignUp/SignUpForm';
import SignInForm from '../SignIn';
import Typing from 'react-typing-animation';
import style from './Landing.module.css';

class Landing extends React.Component {
  render() {
    
    return (
      <div className={style.page}>

        <SignInForm />

      </div>
      );
  }
}

export default Landing;
