import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    firebaseUser: {
      uid: ''
    },
    currentUser: {
      userDetails: {
        firstName: ''
      },
    },
    editMode: false,
  },
  reducers: {
    toggleEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    
  },
});

export const { toggleEditMode  } = userSlice.actions;

//export const selectUsername = state => state.user.username;

export default userSlice.reducer;
