import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Button } from 'react-bootstrap';
import { withFirebase } from '../Firebase';

const AddLocationPage = (props) => (
  <div>
    <h1>Add location</h1>
    <AddLocationForm authUserUID={props.authUserUID} />
  </div>
);

const INITIAL_STATE = {
  locationName: '',
  description: '',
  xCoordinate: 0,
  yCoordinate: 0,
  zCoordinate: 0,
  error: null,
};

class AddLocationFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    console.log('this.props: ', this.props);
  }

  onSubmit = event => {
    const { locationName, description, xCoordinate, yCoordinate, zCoordinate } = this.state;

    const locationObject = {
      locationName: locationName, 
      description: description, 
      xCoordinate: xCoordinate, 
      yCoordinate: yCoordinate, 
      zCoordinate: zCoordinate
    }

    this.props.firebase
      .locations(this.props.authUserUID)
      .push(locationObject)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        console.log('added location');
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { locationName, description, xCoordinate, yCoordinate, zCoordinate, error } = this.state;

    const isInvalid = locationName === '';

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group>
            <Form.Label>Location name</Form.Label>
            <Form.Control type="text" name="locationName" value={locationName} onChange={this.onChange} placeholder="Enter location name" />
        </Form.Group>

        <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows="3" name="description"  value={description} onChange={this.onChange} placeholder="Enter description (optional)" />
        </Form.Group>

        <Form.Group>
            <Form.Label>X Coordinate</Form.Label>
            <Form.Control type="number" name="xCoordinate"  value={xCoordinate} onChange={this.onChange} placeholder="Enter X coordinate" />
        </Form.Group>

        <Form.Group>
            <Form.Label>Y Coordinate</Form.Label>
            <Form.Control type="number" name="yCoordinate"  value={yCoordinate} onChange={this.onChange} placeholder="Enter Y coordinate" />
        </Form.Group>

        <Form.Group>
            <Form.Label>Z Coordinate</Form.Label>
            <Form.Control type="number" name="zCoordinate"  value={zCoordinate} onChange={this.onChange} placeholder="Enter Z coordinate" />
        </Form.Group>

        <Button variant="primary" disabled={isInvalid} type="submit">
            Submit
        </Button>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const AddLocationForm = compose(
  withRouter,
  withFirebase,
)(AddLocationFormBase);

export default AddLocationPage;

export { AddLocationForm };
