import React from 'react';
import style from './SignOut.module.css';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <div onClick={firebase.doSignOut} className={style.signOut}>
    Sign Out
  </div>
);

export default withFirebase(SignOutButton);
