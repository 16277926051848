import React from 'react';
import SignUpForm from './SignUpForm';
import style from './SignUpPage.module.css';

class SignUp extends React.Component {
  render() {
    return (
      <div className={style.page}>
        <h1>SignUp</h1>
        <SignUpForm />
      </div>
      );
  }
}

export default SignUp;
